import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

// Check if user must register for hotspot
const userMustRegister = process.env.VUE_APP_HOTSPOT_USER_MUST_REGISTER !== 'false'

// Retaining only related routes
const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/customer/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['customer'],
    },
  },
  {
    path: '/internet-plans',
    name: 'internet-plans',
    component: () => import('@/views/customer/pages/InternetPlans.vue'),
    meta: {
      requiresAuth: true,
      roles: ['customer'],
    },
  },
  {
    path: '/hotspot/internet-plans',
    name: 'hotspot-internet-plans',
    component: () => import('@/views/customer/pages/hotspot/InternetPlans.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: userMustRegister,
      roles: ['customer'],
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/customer/pages/Invoices.vue'),
    meta: {
      requiresAuth: true,
      roles: ['customer'],
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/customer/pages/Payments.vue'),
    meta: {
      requiresAuth: true,
      roles: ['customer'],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/customer/settings/Settings.vue'),
    meta: {
      requiresAuth: true,
      roles: ['customer'],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/customer/pages/Login.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['customer'],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['customer'],
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['customer'],
    },
  },
  {
    path: '/hotspot/login',
    name: 'hotspot-login',
    component: () => import('@/views/customer/pages/hotspot/Login.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/hotspot/register',
    name: 'hotspot-register',
    component: () => import('@/views/customer/pages/hotspot/Register.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/hotspot/forgot-password',
    name: 'hotspot-forgot-password',
    component: () => import('@/views/customer/pages/hotspot/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/otp-verification',
    name: 'otp-verification',
    component: () => import('@/views/customer/pages/hotspot/OtpVerification.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const { isUserAuthenticated } = store.getters
  const { userInfo } = store.getters

  // Redirect authenticated users away from the login page
  if (to.name === 'login' && isUserAuthenticated) {
    return next({ name: 'dashboard' })
  }

  // Redirect from 'hotspot-register' to 'hotspot-internet-plans' if userMustRegister is false
  if (to.name === 'hotspot-register' && !userMustRegister) {
    return next({
      name: 'hotspot-internet-plans',
      query: to.query, // Preserve query parameters
    })
  }

  // Handle routes that require authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserAuthenticated) {
      // Redirect unauthenticated users to login
      return next({ name: 'login' })
    }
    const userRoles = userInfo?.roles || []
    if (to.meta.roles && to.meta.roles.some(role => userRoles.includes(role))) {
      // Allow access if user has required role
      return next()
    }

    // Redirect to error page if user doesn't have required role
    return next({ path: 'error-403' })
  }

  // Continue navigation for all other cases
  return next()
})

export default router
